import { Splide } from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

document.addEventListener("DOMContentLoaded", function () {
	let slideLength_top; //TOP FVスライド
	let slideLength_activty; //TOP カローラ愛媛の取り組み
	let slideLength_event; //TOP イベントスライド
	let slideLength_ucar; //中古車 TOPおすすめスライド


	const elms_top = document.querySelector(".splide-top");
	if (elms_top) {
		slideLength_top = elms_top.querySelectorAll(".splide__slide").length; //スライドの枚数を取得
		let options_top = {
			type: "fade",
			// perPage: 1,
			autoplay: true, // 自動再生
			interval: 4000, // 自動再生の間隔
			// autoHeight: true,
			speed: 2000,
			rewind: true,
			pagination: false,
			arrows: false,
			drag: false,
			pauseOnHover: false,
			pauseOnFocus: false,
		};
		new Splide('.splide-top', options_top).mount();
	}

	const elms_activty = document.querySelector(".splide-activty");
	if (elms_activty) {
		slideLength_activty = elms_activty.querySelectorAll(".splide__slide").length; //スライドの枚数を取得
		let options_activty = {
			type: 'loop',
			drag: 'free',
			focus: 'center',
			// perPage: 4,
			autoScroll: {
				speed: 1,
			},
			autoWidth: true,
			arrows: false,
			gap: '3rem',
			pagination: false,
		};
		// addOptions = {
		// 	arrows: slideLength === 1 ? false : true, //arrowsをfalseにしただけだとスライド自体はできてしまうのでdestroyする↓
		// 	destroy: slideLength === 1 ? "completely" : false //destroy:trueはブレイクポイントを監視する場合。completelyは完全に削除する。
		// };
		// Object.assign(options, addOptions);

		new Splide('.splide-activty', options_activty).mount({ AutoScroll });
	}


	const elms_event = document.querySelector(".splide-event");
	if (elms_event) {
		slideLength_event = elms_event.querySelectorAll(".splide__slide").length; //スライドの枚数を取得
		let options_event = {
			type: "loop",
			perPage: 1,
			autoplay: true, // 自動再生
			interval: 4000, // 自動再生の間隔
			autoHeight: true,
			speed: 2000,
			arrows: slideLength_event === 1 ? false : true, //arrowsをfalseにしただけだとスライド自体はできてしまうのでdestroyする↓
			destroy: slideLength_event === 1 ? "completely" : false //destroy:trueはブレイクポイントを監視する場合。completelyは完全に削除する。
		};
		// addOptions = {
		// 	arrows: slideLength === 1 ? false : true, //arrowsをfalseにしただけだとスライド自体はできてしまうのでdestroyする↓
		// 	destroy: slideLength === 1 ? "completely" : false //destroy:trueはブレイクポイントを監視する場合。completelyは完全に削除する。
		// };
		// Object.assign(options, addOptions);

		new Splide('.splide-event', options_event).mount();
	}


	const elms_ucar = document.querySelector(".splide_reco");
	// console.log(elms_ucar);
	if (elms_ucar) {
		slideLength_ucar = elms_ucar.querySelectorAll(".splide__slide").length; //スライドの枚数を取得
		console.log(slideLength_ucar);
		let options_ucar = {
			type: 'loop',
			perPage: 3,
			perMove: 1,
			autoplay: true, // 自動再生
			interval: 4000, // 自動再生の間隔
			autoHeight: true,
			speed: 2000,
			gap: 48,
			pagination: false,
			arrows: slideLength_ucar <= 3 ? false : true, //arrowsをfalseにしただけだとスライド自体はできてしまうのでdestroyする↓
			destroy: slideLength_ucar <= 3 ? "completely" : false, //destroy:trueはブレイクポイントを監視する場合。completelyは完全に削除する。
			breakpoints: {
				640: {
					perPage: 1,
					arrows: slideLength_ucar === 1 ? false : true, //arrowsをfalseにしただけだとスライド自体はできてしまうのでdestroyする↓
					destroy: slideLength_ucar === 1 ? "completely" : false, //destroy:trueはブレイクポイントを監視する場合。completelyは完全に削除する。
				},
			}
		};
		new Splide('.splide_reco', options_ucar).mount();

	}

});
